import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import { TimePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
// import TimeFnsUtils from '@time-io/time-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderTimePickerField from '../../../shared/components/form/TimePicker';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Attendance_add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      posts1: [],
      formData: {
        employee_id: '',
        attendance_date: '',
        clock_in:'',
        clock_out:''
    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  } 
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
     // alert(answer_array['3']);

      fetch(myConstClass.BASE_URL+'/user/getAttendanceInfo/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))
      
    }
  }

  componentWillMount(){
   
    fetch(myConstClass.BASE_URL+'attendance/get_all_employees/')
   .then(response => response.json())
   .then(response =>{this.setState({posts1: response})}).catch(err => console.log(err))      
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`attendance/add_attendance/`, {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/attendance/');
              }else{
                toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { posts1} = this.state;
    let optionItems = posts1.map((posts1) =>
    <option key={posts1.id} value = {posts1.id}>{posts1.first_name+' '+posts1.last_name}</option>
);
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Attendance</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
      <Row>        
        <Col md={6} lg={6}>           
        {/* <div>
         <Field
              name="employee_id"
              component={renderTextField}
              select
              label="Select employee"> */}
              {/* {
                  posts1.map((i, k) => {
                          return (
                          <MenuItem key={k} className="material-form__option" value={i.id}>{i.first_name}
                          </MenuItem>
                )
              })} */}
              {/* <MenuItem className="material-form__option" value= {posts1.id}>{posts1.first_name}</MenuItem> */}
              {/* <MenuItem className="material-form__option" value="1">test last</MenuItem>
              <MenuItem className="material-form__option" value="2">john doe</MenuItem>
              <MenuItem className="material-form__option" value="3">James Will</MenuItem>
              <MenuItem className="material-form__option" value="3">Tony  Stark</MenuItem>
              
            </Field>
         </div>         */}
         <div>
         <h6>Select Employee</h6>
              <select name ='employee_id'  value={formData.employee_id} style={{width: '512px',height:'32px', border:'1px'}}>
                {optionItems}
             </select> 
          </div> 
          <br/> <br/>
          <div>
            <span className="form__form-group-label">In-Time</span>
            <Field
              name="clock_in"
              component={renderTimePickerField}
            />
          </div>
          <br/>
        </Col>
        
       
       <Col md={6} lg={6}> 
       <div>            
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
          className="material-form__field"
          onChange={this.handleDateChange}
          name="attendance_date"
          placeholder="Attendance Date"
          label="Attendance Date"
          value={this.state.date}
          format="dd-MM-yyyy"
        />
         </MuiPickersUtilsProvider>
         </div>
         <br/>

         <div>
          <span className="form__form-group-label">Out-Time</span>
            <Field
              name="clock_out"
              component={renderTimePickerField}
            />
          </div>
          <br/>         
        </Col>
       </Row>
       <br />

          <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
               
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'attendance_add', // a unique identifier for this form
})(withTranslation('common')(Attendance_add));
