import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
import Gallery from 'react-grid-gallery';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';



class Attendance_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
       checkIn:[],
       checkOut:[],
      formData: {
        organization: '',
        title: '',
        announcement:'',
    },
    }
    this.flag = 0;    
  } 

  componentDidMount(){
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
   fetch(myConstClass.BASE_URL+'attendance/getAttendanceInfo/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))  
  
 
    }
  }

  componentWillMount(){
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
    fetch(myConstClass.BASE_URL+'attendance/getCheckInLocationInfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>{this.setState({checkIn: response})}).catch(err => console.log(err)) 
  
    fetch(myConstClass.BASE_URL+'attendance/getCheckOutLocationInfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>{this.setState({checkOut: response})}).catch(err => console.log(err)) 
  }
}

render() {
  const {formData,checkIn,checkOut} = this.state;
  const heading = formData.employee_id;
  const check_in = `http://freeattendance.nutraglobe.com/backend/uploads/tmp/`+formData.check_in_image;
  const check_out = `http://freeattendance.nutraglobe.com/backend/uploads/tmp/`+formData.check_out_image;
  // const check_in = `http://localhost/fap/uploads/tmp/`+formData.check_in_image;
  // const check_out = `http://localhost/fap/uploads/tmp/`+formData.check_out_image;
  const file_link = 'https://www.google.com/maps/search/?api=1&query='+checkIn.location_lat+','+checkIn.location_long;
  const file_link_out = 'https://www.google.com/maps/search/?api=1&query='+checkOut.location_lat+','+checkOut.location_long;
  
  const location = `http://localhost/fap/img/location-map.png`;
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">View CheckIn/ CHeckOut Images
       </h3>       
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      <Row>
      <Col md={12} lg={12}>
      <h3>
      {/* <center>{heading}</center>   */}
      </h3>
      <hr/>
      </Col>
      </Row>
      <Row>
      <Col md={6} lg={6}>
      <h3>
     Check-In Image
      </h3>
      {/* {posts.chock_in_image} */}
      {/* <Gallery images={}/> */}
      <img src={check_in}  style={{width: '160px',height:'100px'}}/><br /><br/>
      </Col>
      <Col md={6} lg={6}>
      <h5>Get Location</h5>  <a target="_blank"  href={file_link}><img src= {location} style={{width: '25px',height:'16px'}}/></a> 
       </Col>
      </Row><br/><br/>

       <Row>
      <Col md={6} lg={6}>
      <h3>
     Check-out Image
      </h3>
      <img src={check_out}  style={{width: '160px',height:'100px'}}/><br /><br/>
      </Col>
      <Col md={6} lg={6}>
      <h5>Get Location</h5>  <a target="_blank"  href={file_link_out}><img src= {location} style={{width: '25px',height:'16px'}}/></a> 
      </Col>
      </Row>
      <hr/>
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Attendance_view);
