import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
// import FileUploadDefault from './components/FileUploadDefault';
// import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';   
const logo = `http://freeattendance.nutraglobe.com/backend/img/clock_new.png`;
const zone_logo = `http://freeattendance.nutraglobe.com/backend/img/Work-Zone-Plus-logo.png`;
// const logo = `http://localhost/fap/index.php/img/clock_new.png`;
// const zone_logo = `http://localhost/fap/index.php/img/Work-Zone-Plus-logo.png`;
// import CKEditor from "react-ckeditor-component";
const renderTextField = ({
  
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Sign_up extends Component {

  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      date: Date.now(),
      selectedFile: null,
      posts: [],
      organization:[],
      formData: {
        name: '',
        position: '',
        organization:'',
        email:'',
        mobile_no:'',
        city:'',
        country:'',
    },
    submitted: false,
    }
    this.handleChangeText = this.handleChangeText.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

    //  updateContent(newContent) {
    //   this.setState({
    //    content: newContent
    //  })
    // }

    // onChangeCk(evt){
    //     console.log("onChange fired with event info: ", evt);
    //     var newContent = evt.editor.getData();
    //     this.setState({
    //       content: newContent
    //     })
    //   }

    //  componentDidMount(){  
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //    // alert(answer_array['3']);
    //   fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    //  .then(response => response.json())
    //  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))    
    //   }
    // }
    
 
    handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    }
    handleDateChange = (date) => {  
    this.setState({ date });
    };

    handleChangeText(event) {
      this.setState({value: event.target.value});
    }

    onChangeHandler=event=>{

      console.log(event.target.files[0]);
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
  
  }
  
    componentWillMount(){
    const newLocal = fetch(myConstClass.BASE_URL + 'organization/get_all_organizations/')
        .then(response => response.json())
        .then(response => { this.setState({ organization: response }); }).catch(err => console.log(err));      
    }

    // componentDidMount(){
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //  fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
        
    //   }
    // }

  
    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
                const data = new FormData() 
                data.append('file', this.state.selectedFile);
                data.append('formdata',JSON.stringify($('#pform').serializeArray()));
            setTimeout(() => this.setState({ submitted: false }), 5000);
           fetch(myConstClass.BASE_URL+`user/add_request/`, {
              method: 'POST',
              // body: JSON.stringify($('#pform').serializeArray()),
              body:data,

            }).then(response =>  response.json()).then((response) => {
              if(response == true){ 
                toast('Request Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS});
                // this.props.history.push('/forms/sign_up_list/');
              }  else if(response == 2){
                toast('Email ID Already Exists..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }else{
                toast('Request Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
      });
   }


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { organization} = this.state;
    
    return (
    <Container>
     {/* <Row> */}
      {/* <Card> */}
       <div>
        <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
        <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
      </div>        
       <ValidatorForm
            className="material-form" id="pform" 
            onSubmit={this.handleSubmit}
        >          
       <Row>   
       <div class= 'col-md-3' style ={{backgroundColor: 'DarkCyan'}}> </div>
         <div class= 'col-md-6'>      
           <div class = 'col-md-3' ></div>
            <div  style ={{backgroundColor: '#ebebeb'}}>  <img class="work-zone-clock" src={logo}/><img src={zone_logo}  class="work-zone-plus"/><br/><hr/></div>
             <div class = 'col-md-3'  style={{left: '253px'}}>  <h3  className="page-title" >Sign Up Form</h3></div>
              <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="name"
                    placeholder="Name"
                    label="Name"
                    value={formData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  />  
               <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="organization"
                    placeholder="Organization"
                    label="Organization"
                    value={formData.organization}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  />        
              <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="mobile_no"
                    placeholder="Mobile No"
                    label="Mobile No"
                    value={formData.mobile_no}
                  validators={['required','matchRegexp:^[0-9]+$']}
                  errorMessages={['this field is required','Enter Numbers..']}
                  />
                  <br/>
              <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    value={formData.email}
                  validators={['required','isEmail']}
                  errorMessages={['this field is required','Enter valid email address']}
                  />
                  <br/>
              <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="position"
                    placeholder="position"
                    label="Position"
                    value={formData.position}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  /> 
               <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="website"
                    placeholder="Website"
                    label="Website"
                    value={formData.website}
                  />  
               <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="city"
                    placeholder="City"
                    label="City"
                    value={formData.city}
                  />            
              <TextValidator
                  onChange={this.handleChange}
                  className="material-form__field"
                    name="country"
                    placeholder="Country"
                    label="Country"
                    value={formData.country}
                  /> <br/>
                  <h5> Upload Personnal ID Proof </h5><br/>
                    <input type="file" name="file" onChange={this.onChangeHandler}/>
                    <br/> <br/>
                  <h5><b>Please Note *</b></h5>
                  <h5>1) Organisation must be a registered Identity in the country of issue.</h5>
                  <h5>2) Personal ID Document is need to verify system operator.</h5>
                  <h5>3) Subject to terms and conditions.</h5><br/>
              <Button
              style={{left: '275px'}} 
              color="primary"
              variant="contained"
              type="submitborderBottomLeftRadius"
              disabled={submitted}
          >
        {
            (submitted && 'Your form is submitted!')
            || (!submitted && 'Submit')
        }
        </Button> <div class='col-md-4'></div> <div  style={{left: '275px'}}> <p>Already have an account? <Link to="/log_in">Login</Link></p><hr/></div>
      
         </div>       
          {/* <div style={{margin-left: '522px'}}> */}
           <div class= 'col-md-3' style ={{backgroundColor: 'DarkCyan'}}> </div>
           {/* <Col style = {{backgroundColor: 'DarkCyan'}} md={3} lg={3}> <div  ></div>  </Col>      */}
        </Row>
       <br />
        {/* </div> */}
     </ValidatorForm>
    {/* </CardBody> */}
    {/* </Card> */}
  <div class= 'col-md-' style ={{backgroundColor: 'DarkCyan'}}> </div>
 {/* </Row> */}
</Container>
)
}
}
export default reduxForm({
  form: 'sign up', // a unique identifier for this form
})(withTranslation('common')(Sign_up));


