import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
class Ads_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Organization', prop: 'org_title', filterable: true },
      { title: 'Sequence', prop: 'sequence_no', filterable: true },
      // { title: 'Image', prop: 'image', filterable: true },
      { title: 'Redirect URL', prop: 'redirect_url' },
      { title: 'Action', prop: 'action',cell: (row)=><div><i id={row.id} className="fa fa-edit ptr_css" onClick={() =>this.edit(row.id)}></i>&nbsp;&nbsp;</div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }
  edit(e) { 
     this.props.history.push('/forms/edit_ad/'+e);
    }



  remove(e) { 
    var admin_id = sessionStorage.getItem('username');
    this.flag = 1;
    fetch(myConstClass.BASE_URL+`organization/deleteAd`, {
    method: 'POST',
    body: e,
  }).then(toast('Ad Deleted Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),
  
  fetch(myConstClass.BASE_URL+'organization/getAds/'+admin_id)
  .then(response => response.json())
  .then(response =>{
    let i=1;
    response.rows.forEach(element => {
    element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css" onClick={() =>this.edit(element.uid)}></i></div>;
     // console.log(element);
     //element.uid=i;
     i++;
    });
    this.setState({posts: response})
  })
  .catch(err => console.log(err))
  )
  .catch();
  }



  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    var admin_id = sessionStorage.getItem('username');
    fetch(myConstClass.BASE_URL+'organization/getAds/'+admin_id)
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.rows.forEach(element => {
      element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css" onClick={() =>this.edit(element.uid)}></i></div>;
      // element.action = <div><i id={element.uid} className="fa fa-edit ptr_css"></i></div>;
       
      // console.log(element);
       //element.uid=i;
       i++;
      });
      this.setState({posts: response})
    })
    .catch(err => console.log(err))
        
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Ads
      </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}

    <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Ads_list);
