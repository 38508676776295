import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
class Logs_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'User ID', prop: 'user_id', filterable: true },
      { title: 'User IP', prop: 'user_ip', filterable: true },
      { title: 'Location', prop: 'location', filterable: true },
      { title: 'Operation', prop: 'operation' },
      { title: 'Affected Table', prop: 'affected_table' },
      { title: 'Rows', prop: 'rows' },
      { title: 'Created At', prop: 'timestamp' },
     ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    var admin_id = sessionStorage.getItem('username');
    fetch(myConstClass.BASE_URL+'user/getLogs/'+admin_id)
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.rows.forEach(element => {
       i++;
      });
      this.setState({posts: response})
    })
    .catch(err => console.log(err))
        
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All System Logs
      </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>

    <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Logs_list);
