import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderFileInputField from '../../../shared/components/form/FileInput';
// import CKEditor from "react-ckeditor-component";
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Edit_ad extends Component {

  constructor(props) {
    super(props);
    this.state = { file: ''};
    this.state = {file: '',imagePreviewUrl: ''};
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      date: Date.now(),
      // content: 'content',
      posts: [],
      organization: [],
      formData: {
        organization_id: '',
        title: '',
        announcement:'',
    },
    submitted: false,
    }
    this.handleChangeText = this.handleChangeText.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

    //  updateContent(newContent) {
    //   this.setState({
    //    content: newContent
    //  })
    // }

    // onChangeCk(evt){
    //     console.log("onChange fired with event info: ", evt);
    //     var newContent = evt.editor.getData();
    //     this.setState({
    //       content: newContent
    //     })
    //   }

    //  componentDidMount(){  
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //    // alert(answer_array['3']);
    //   fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    //  .then(response => response.json())
    //  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))    
    //   }
    // }

 
    handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    }
    handleDateChange = (date) => {  
    this.setState({ date });
    };

    handleChangeText(event) {
      this.setState({value: event.target.value});
    }
    _handleImageChange(e) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
      reader.readAsDataURL(file)
    }
  
    componentWillMount(){
      fetch(myConstClass.BASE_URL + 'organization/get_all_organizations/')
   .then(response => response.json())
   .then(response => { this.setState({ organization: response }); }).catch(err => console.log(err));      
    }

    componentDidMount(){
      const answer_array = this.props.location.pathname.split('/');
      if(answer_array['3']){
     fetch(myConstClass.BASE_URL+'organization/getAdInfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
        
      }
    }

//     componentWillMount(){  
//       let reader = new FileReader();
//       let file = formData.image ;
//       reader.onloadend = () => {
//         this.setState({
//           file: file,
//           imagePreviewUrl: reader.result
//         });
//       }
//       reader.readAsDataURL(file)
// }

  
    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
          setTimeout(() => this.setState({ submitted: false }), 5000);
          const data = new FormData() 
          data.append('file', this.state.file);
          data.append('formdata',JSON.stringify($('#pform').serializeArray()));
           const answer_array = this.props.location.pathname.split('/'); 
      fetch(myConstClass.BASE_URL+`organization/edit_ad/`+answer_array['3'], {
              method: 'POST',
              // body: JSON.stringify($('#pform').serializeArray()),
              body: data,

            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Ad Updated Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/ads_list/');
              }else{
                toast('Ad Updation Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
      });
   }


  render() {
    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { organization} = this.state;
    let optionItems = organization.map((organization) =>
    <option key={organization.id} value={organization.id}>{organization.title}</option>
);
    const file_link = `http://freeattendance.nutraglobe.com/backend/uploads/banners/`+formData.image;

    // const file_link = `http://localhost/fap/uploads/banners/`+formData.image;

    // <a href={file_link}><h5>{formData.personal_doc}</h5></a>

    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} />);    
    } else {
      $imagePreview = (<div className="previewText"></div>);   
    }
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Ad</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>       
    <Card>
      <CardBody>
        <div className="card__title">
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        </div>        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >          
      <Row>        
       <Col md={6} lg={6}> 
        {/* <div>
         <Field
              name="organization_id"
              component={renderTextField}
              select
              label="Select Organization">
                <MenuItem className="material-form__option" value="1">Header</MenuItem>
               <MenuItem className="material-form__option" value="2">Test Org</MenuItem>
              {/* <MenuItem className="material-form__option" value= {posts1.id}>{posts1.title}</MenuItem> */}
            {/* </Field>
         </div> */}
               <div>
             <h6>Select Organization</h6>
              <select name ='organization_id'  value={formData.organization_id} style={{width: '512px',height:'32px', border:'1px'}}>
                {optionItems}
             </select> 
          </div> 
           <br />
         <div>
            <TextValidator
              onChange={this.handleChange}
              className="material-form__field"
                type= 'number'
                name="sequence_no"
                placeholder="Sequence No"
                label="Sequence No"
                value={formData.sequence_no}
                validators={['required','matchRegexp:^[0-9]+$']}
                errorMessages={['this field is required','Enter Numbers..']}
              />         
            <br/> <br/>
          </div>  
          <div>
             <TextValidator
              onChange={this.handleChange}
              className="material-form__field"
                type= 'text'
                name="redirect_url"
                placeholder="Redirect URL"
                label="Redirect URL"
                value={formData.redirect_url}
              />       
            <br/> 
          </div> 
          <div>
         <Field
              name="status"
              component={renderTextField}
              select
              label="Select Status">
                <MenuItem className="material-form__option" value="1">Active</MenuItem>
               <MenuItem className="material-form__option" value="2">In-Active</MenuItem>
           </Field>
         </div>  
        </Col>
        <Col md={6} lg={6}>
          <div>
              <h5>Select Image</h5><br/> <img src={file_link}  style={{width: '50px',height:'50px'}}/>;<br /><br/>
             <input className="fileInput" 
              name = 'image'
              id= 'file'
              component={renderFileInputField}
              type="file"             
              onChange={(e)=>this._handleImageChange(e)} />
               <div className="imgPreview">
                 {$imagePreview}
               </div>
            </div> <br />  <br /> 
        </Col>
       </Row>
     <br />
    <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitted}
    >
        {
            (submitted && 'Your form is submitted!')
            || (!submitted && 'Update')
        }
        </Button>
     </ValidatorForm>
    </CardBody>
  </Card>
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
 </Row>
</Container>
)
}
}



export default reduxForm({
  form: 'edit_ad', // a unique identifier for this form
})(withTranslation('common')(Edit_ad));
