import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderTimePickerField from '../../../shared/components/form/TimePicker';
import renderFileInputField from '../../../shared/components/form/FileInput';
import axios from 'axios';
import ImageUploader from 'react-images-upload';
// import FineUploaderTraditional from 'fine-uploader-wrappers';

// var path = "/0"; 
// const uploader = new FineUploaderTraditional({
//   options: {
//       chunking: {
//           enabled: true
//       },
//       cors: {
//         //all requests are expected to be cross-domain requests
//         expected: true,
//     },
//       request: {
//           endpoint: myConstClass.BASE_URL+'organization/add_organization_banners'+path,
//           method: "POST",
//       },
//       // request:{
//       //   endpoint: myConstClass.BASE_URL+'product/currentImages/'+sarray[sarray.length-1],
//       // },    
//       retry: {
//           enableAuto: true   
//       }
//   }
// });


const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}s
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Organization extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      formData: {
        title: '',
        addressline1: '',
        addressline2:'',
        city:'',
        state:'',
        country:'',
        owner:'',
        monday_in_time:'',
        monday_out_time:'',
        tuesday_in_time:'',
        tuesday_out_time:'',
        wednesday_in_time:'',
        wednesday_out_time:'',
        thursday_in_time:'',
        thursday_out_time:'',
        friday_in_time:'',
        friday_out_time:'',
        saturday_in_time:'',
        saturday_out_time:'',
        sunday_in_time:'',
        sunday_out_time:'',
    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
     // alert(answer_array['3']);

    fetch(myConstClass.BASE_URL+'/organization/getOrganization/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))
      
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleSubmit = () => {
  var admin_id = sessionStorage.getItem('username'); 
  this.setState({ submitted: true }, () => {  
    const fd = new FormData();
    // const ser_data = JSON.stringify($('#pform').serializeArray());
    // fd.append(ser_data, this.state[ser_data]);
    for (let file in this.state) {
      fd.append(file, this.state[file]);
    }
  

      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`organization/add_organization/`+admin_id, {
              method: 'POST',
              body:JSON.stringify($('#pform').serializeArray())
            }).then(response =>  response.json()).then((response) => {
              if(response != ''){

                toast('Organization Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/organization_list/');
              }else{
                toast('Organization Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    var admin_id = sessionStorage.getItem('username'); 
    const { formData, submitted } = this.state;
    const { posts} = this.state;

    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Organization</h3>
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody> 
        <div className="card__title">
       </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
       <Row>         
        <Col md={6} lg={6}>           
          <TextValidator
            onChange={this.handleChange}
              name="title"
              placeholder="Title"
              label="Title"
              className="material-form__field"
              value={formData.title}
             validators={['required']}
             errorMessages={['this field is required']}
            />        
           <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="addressline1"
              placeholder="Address line 1"
              label="Address line 1"
              value={formData.addressline1}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="addressline2"
              placeholder="Address line 2"
              label="Address line 2"
              value={formData.addressline2}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="city"
              placeholder="City"
              label="City"
              value={formData.city}
            />
          <br/>    
        </Col>      
        <Col md={6} lg={6}>         
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="state"
              placeholder="State"
              label="State"
              value={formData.state}
            />
           <br/> 
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="country"
              placeholder="Country"
              label="Country"
              value={formData.country}
            />
          <br/> 
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="owner"
              placeholder="Owner"
              label="Owner"
              value={formData.owner}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/> 
        </Col>
        <Col md={3} lg={3}>      
          <h5>Day</h5>
          <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>In-Time</h5>
            <br/> 
        </Col>
        <Col md={3} lg={3}>       
          <h5>Out-Time</h5>
          <br/> 
        </Col>
       <Col md={4} lg={4}>      
          <h5>Monday</h5>
          <br/> <br/>
          <h5>Tuesday</h5>
          <br/> <br/>
          <h5>Wednesday</h5>
          <br/>  <br/>
          <h5>Thursday</h5>
          <br/>  <br/>
          <h5>Friday</h5>
          <br/>  <br/>
          <h5>Saturday</h5>
          <br/>  <br/>
          <h5>Sunday</h5>
          <br/> 
        </Col>
        <Col md={3} lg={3}>
        <div>
        <Field
              name="monday_in_time"
              component={renderTimePickerField}
            /></div><br />
         <Field
              name="tuesday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="wednesday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="thursday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="friday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="saturday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="sunday_in_time"
              component={renderTimePickerField}
            /> <br/>
        </Col>
        <Col md={3} lg={3}>
        <Field
              name="monday_out_time"
              component={renderTimePickerField}
            /><br />
         <Field
              name="tuesday_out_time"
              component={renderTimePickerField}
            /> <br/>
         <Field
              name="wednesday_out_time"
              component={renderTimePickerField}
            /> <br/>
         <Field
              name="thursday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="friday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="saturday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="sunday_out_time"
              component={renderTimePickerField}
            /> <br/>
        </Col>
        <Col md={6} lg={6}>
            <div>
            <Field
          name="status"
          component={renderTextField}
          select
          label="Select Status"             
        >
        <MenuItem className="material-form__option" value="0">Pending</MenuItem>
        <MenuItem className="material-form__option" value="1">Approved / In-active</MenuItem>
       
        </Field>
            {/* {status_a} */}
           </div>
          </Col>
       </Row>
     <br /><br />        
             <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>           
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )
  }
}

export default reduxForm({
  form: 'organization', // a unique identifier for this form
})(withTranslation('common')(Organization));
