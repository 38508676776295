import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import { TimePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
// import TimeFnsUtils from '@time-io/time-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Leave_edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      posts1: [],
      formData: {
        employee_id: '',
        leave_type_id: '',
        from_date:'',
        to_date:'',
        start_date_time:'',
        end_date_time:'',
        reason:'',
        status:'',
        total_days:''
    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  } 
  componentDidMount(){  
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
      fetch(myConstClass.BASE_URL+'attendance/getLeaveInfo/'+answer_array['3'])
     .then(response => response.json())
     .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))     
    }
  }

  
  componentWillMount(){
   
    fetch(myConstClass.BASE_URL+'attendance/get_all_employees/')
   .then(response => response.json())
   .then(response =>{this.setState({posts1: response})}).catch(err => console.log(err))      
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    const answer_array = this.props.location.pathname.split('/');
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`attendance/edit_leave/`+answer_array['3'], {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Leave Updated Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/attendance/');
              }else{
                toast('Leave Updation Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { posts1} = this.state;
    let optionItems = posts1.map((posts1) =>
    <option key={posts1.id} value={posts1.id}>{posts1.first_name+' '+posts1.last_name}</option>
);
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Leave</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
          <Row>       
           <Col md={6} lg={6}>           
           {/* <div>
         <Field
              name="employee_id"
              component={renderTextField}
              value={formData.first_name}
              select
              label="Select employee">
              <MenuItem className="material-form__option" value="1">test last</MenuItem>
              <MenuItem className="material-form__option" value="2">john doe</MenuItem>
              <MenuItem className="material-form__option" value="3">James Will</MenuItem>
              <MenuItem className="material-form__option" value="3">Tony  Stark</MenuItem>
            </Field>
         </div>        */}
          <div>
          <h6>Select Employee</h6>
              <select name ='employee_id'  value={formData.employee_id} style={{width: '512px',height:'32px', border:'1px'}}>
                {optionItems}
             </select> 
          </div> 
          <br/>
           
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
          className="material-form__field"
          onChange={this.handleDateChange}
          name="from_date"
          placeholder="From Date"
          label="From Date"
          value={formData.from_date}
          format="yyyy-MM-dd"
           />
         </MuiPickersUtilsProvider>
         <br/>

         <div>
          <Field
              name="from_date_time"
              component={renderTextField}
              select
              label="Select Start Time">
              <MenuItem className="material-form__option" value="1">Starts in first half</MenuItem>
              <MenuItem className="material-form__option" value="2">Starts in second half</MenuItem>
            </Field>
          </div>

          <TextValidator
            onChange={this.handleChange}
              name="reason"
              placeholder="Reason"
              label="Reason"
              className="material-form__field"
              value={formData.reason}
             validators={['required']}
             errorMessages={['this field is required']}
            />         
          <br/>

        </Col>
       
        <Col md={6} lg={6}>     
        <div>
          <Field
              name="leave_type_id"
              component={renderTextField}
              value={formData.leave_type_id}
              select
              label="Select Leave">
              <MenuItem className="material-form__option" value="1">Annual Leave</MenuItem>
              <MenuItem className="material-form__option" value="2">Sick Leave</MenuItem>
              <MenuItem className="material-form__option" value="3">Family Leave</MenuItem>
              <MenuItem className="material-form__option" value="4">Maternal Leave</MenuItem>
              <MenuItem className="material-form__option" value="5">Paternal Leave</MenuItem>
            </Field>
          </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
          className="material-form__field"
          onChange={this.handleDateChange}
          name="to_date"
          placeholder="To Date"
          label="To Date"
          value={formData.to_date}
          format="yyyy-MM-dd"
         />
         </MuiPickersUtilsProvider>
         <br/>
         <div>
          <Field
            name="end_date_time"
            component={renderTextField}
            select
            label="Select End Time">
            <MenuItem className="material-form__option" value="1">Ends in first half</MenuItem>
            <MenuItem className="material-form__option" value="2">Ends in second half</MenuItem>
          </Field>
        </div>
         <div>
          <Field
              name="status"
              component={renderTextField}
              select
              label="Select Status">
              <MenuItem className="material-form__option" value="1">Pending</MenuItem>
              <MenuItem className="material-form__option" value="2">Approved</MenuItem>
              <MenuItem className="material-form__option" value="3">Rejected</MenuItem>
            </Field>
          </div>
       </Col>
      </Row>
       <br />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Update')
                    }
                </Button>             
             </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'leave_edit', // a unique identifier for this form
})(withTranslation('common')(Leave_edit));
