import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody, ButtonToolbar  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import FileUploadDefault from './components/FileUploadDefault';
import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
const file_link = `${process.env.PUBLIC_URL}/SAMPLE File.xlsx`;

class Bulk_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      posts:''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
  }
  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }
  onChangeHandler=event=>{

    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })

}
onClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', sessionStorage.getItem('session_id'));

  fetch(myConstClass.BASE_URL+`/user/import`, {
    method: 'POST',
    body: data,
  }).then(response => response.json())
  .then(response =>this.setState({posts: response}))

}

onSaveClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', sessionStorage.getItem('session_id'));
  fetch(myConstClass.BASE_URL+`/user/save_import_data`, {
    method: 'POST',
    body: data,
  }).then(response =>  response.json()).then((response) => {
    if(response){
      toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
      this.props.history.push('/forms/users/');
    }else{
      toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  })
  .catch();


}

  render() {
   
    return (
      <Container>
    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
    <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    <Row>
      <Col md={12}>
        <h3 className="page-title">Employee Bulk Import</h3>
        <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3>
      </Col>
    </Row>
    <Card>
      <CardBody>
      <form className="form" id="pform" onSubmit={this.handleSubmit}>
       
    
    <Col md={12}>
    <a href={file_link} style={{float:'right'}}>
    <Button color="primary" type="button" className="previous">Download Sample File</Button>
    </a>
    </Col>
    <hr/>
    <Col md={6}>
    <input type="file" name="file" onChange={this.onChangeHandler}/>   
    </Col>
   
      <Col md={6}>
    <button type="button" class="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
    </Col>
       
          
          </form>
         { this.state.posts ?
         <div>
          <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
    <Col md={6}>
  <button type="button" class="btn btn-primary btn-block col-md-4" onClick={this.onSaveClickHandler}>Save</button>
    
    </Col>
    </div>
  : "" }
  
      </CardBody>
      </Card>
  </Container>
    )
  }
}

Bulk_import.propTypes = {
  //handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Bulk_import', // a unique identifier for this form
})(withTranslation('common')(Bulk_import));

