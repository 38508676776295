import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      formData: {
        firstname: '',
        lastname: '',
        email:'',
        mobile_no:'',
        status:''

    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
     // alert(answer_array['3']);

      fetch(myConstClass.BASE_URL+'/user/getAdminInfo/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))
      
    }
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`user/add_admin/`, {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {  
              if(response == true){
                toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/admin_list/');
              }else if(response == 2){
                toast('Email ID Already Exists..!', {containerId: 'B',type: toast.TYPE.ERROR})
              } else {
                toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add HR Admin{posts.sap_code}</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
          <Row>
          
        <Col md={6} lg={6}>
            
            <TextValidator
            onChange={this.handleChange}
              name="firstname"
              placeholder="First name"
              label="First Name"
              className="material-form__field"
              value={formData.firstname}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          
             <br/>
           
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="email"
              placeholder="Email"
              label="Email"
              value={formData.email}
             validators={['required','isEmail']}
             errorMessages={['this field is required','Enter valid email address']}
            />

             <br/>
             <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="mobile_no"
              placeholder="Mobile No"
              label="Mobile No"
              value={formData.mobile_no}
             validators={['required','matchRegexp:^[0-9]+$']}
             errorMessages={['this field is required','Enter Numbers..']}
            />
            <br/>  
            <div>
          <Field
              name="status"
              component={renderTextField}
              select
              label="Select Status">
              <MenuItem className="material-form__option" value="1">Active</MenuItem>
              <MenuItem className="material-form__option" value="2">Inactive</MenuItem>
              
            </Field>
          </div>  
         </Col>
         
          <Col md={6} lg={6}>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="lastname"
              placeholder="Last name"
              label="Last name"
              value={formData.lastname}
             validators={['required']}
             errorMessages={['this field is required']}
            />
           <br/>
         
           <TextValidator
            onChange={this.handleChange}
              name="password"
              placeholder="Password"
              label="Password"
              className="material-form__field"
              value={formData.password}
             validators={['required']}
             errorMessages={['this field is required']}
            />
             <br/>
             <div>
          <Field
              name="gender"
              component={renderTextField}
              select
              label="Select Gender">
              <MenuItem className="material-form__option" value="1">Male</MenuItem>
              <MenuItem className="material-form__option" value="2">Female</MenuItem>
              <MenuItem className="material-form__option" value="3">Others</MenuItem>
            </Field>
          </div>
          

          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
          className="material-form__field"
          onChange={this.handleDateChange}
          name="date_of_birth"
          placeholder="Date of Birth"
          label="Date of Birth"
          value={this.state.date}
          format="dd-MM-yyyy"
        />
         </MuiPickersUtilsProvider> */}
          
           
          </Col>
          </Row>
          <br />
          <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
               
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'admin', // a unique identifier for this form
})(withTranslation('common')(Admin));
