import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarMail from './TopbarMail';
import TopbarNotification from './TopbarNotification';
import TopbarSearch from './TopbarSearch';
import TopbarLanguage from './TopbarLanguage';
// const logo = `${process.env.PUBLIC_URL}/img/ziman_logo4.png`;
const logo = `http://freeattendance.nutraglobe.com/backend/img/clock_new.png`;
const zone_logo = `http://freeattendance.nutraglobe.com/backend/img/Work-Zone-Plus-logo.png`;
// const logo = `http://localhost/fap/index.php/img/clock_new.png`;
// const zone_logo = `http://localhost/fap/index.php/img/Work-Zone-Plus-logo.png`;
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper" style ={{backgroundColor: '#ebebeb'}}>
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility} 
            /><br/>
           <Link to="/dashboard_default" > <img class="dashboar-plus-logo" src={logo}  style={{width: '40px',height:'40px'}}/>  <img class="dashboar-plus-logo" src={zone_logo}  style={{width: '187px',height:'25px'}}/></Link>
            {/* <Link to="/dashboard_default" ><img className="top_logo" src={} alt="avatar" /></Link> */}
          </div>
          <div className="topbar__right">            
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;

