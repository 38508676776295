import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import * as myConstClass from '../../../constant.js';

class SidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: [],     
    }

  } 
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

    
  componentWillMount(){

    var admin_id = sessionStorage.getItem('username');
    fetch(myConstClass.BASE_URL+'user/get_log_admin/'+admin_id)
   .then(response => response.json())
   .then(response =>{this.setState({posts: admin_id})}).catch(err => console.log(err))   

  }

  render() {
    const { changeToLight, changeToDark } = this.props;
    const { posts} = this.state;
   const  id = this.state.posts;
   let hr_admin;
   let manage_ads;
   let sign_up;
   let attendance;
   let org;
   let edit = <i class="far fa-clone"></i>;
   if(id == 10){
       hr_admin  =  <SidebarCategory title="Manage HR Admin" icon="user">        
                    <SidebarLink title="Add Admin" route="/forms/add_admin" onClick={this.hideSidebar} />
                    <SidebarLink title="List Admin"  route="/forms/admin_list" onClick={this.hideSidebar} />
                  </SidebarCategory>;
      manage_ads = '';  
      sign_up    = <SidebarLink title="Sign Up Requests" icon="user"  route="/forms/Sign_up_list" onClick={this.hideSidebar} />;
      attendance =  <SidebarCategory  title="Manage Attendance" icon="calendar-full">
                      <SidebarLink title="Attendance List"  route="/forms/attendance" onClick={this.hideSidebar} />  
                    </SidebarCategory> ;
      org        =  <SidebarCategory title="Manage Organization" icon="home">
                       <SidebarLink title="Add Organization"  route="/forms/add_organization/" onClick={this.hideSidebar} /> 
                       <SidebarLink title="List Organization" route="/forms/organization_list" onClick={this.hideSidebar} />
                    </SidebarCategory>;
     manage_ads  =  <SidebarCategory title="Manage Ads" icon="store">
                      <SidebarLink title="Add Ads"  route="/forms/add_ad" onClick={this.hideSidebar} />   
                       <SidebarLink title="Ads List"  route="/forms/ads_list" onClick={this.hideSidebar} />  
                    </SidebarCategory> ;
   } else { 
     hr_admin    = '';
     manage_ads  =  <SidebarCategory title="Manage Ads" icon="store">
                         <SidebarLink title="Ads List"  route="/forms/ads_list" onClick={this.hideSidebar} />  
                    </SidebarCategory> ;
     sign_up     = '';
     attendance  =  <SidebarCategory title="Manage Attendance" icon="calendar-full">
                     <SidebarLink title="Add Attendance"  route="/forms/attendance_add" onClick={this.hideSidebar} />  
                     <SidebarLink title="Attendance List"  route="/forms/attendance" onClick={this.hideSidebar} />  
                   </SidebarCategory> ;
     org         = <SidebarCategory title="Manage Organization" icon="home">
                    <SidebarLink title="Add Organization"  route="/forms/add_organizations/" onClick={this.hideSidebar} /> 
                    <SidebarLink title="List Organization" route="/forms/organization_list" onClick={this.hideSidebar} />
                  </SidebarCategory>;
   }
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="home" route="/dashboard_default" onClick={this.hideSidebar} />

        {hr_admin}
          {/* <SidebarCategory title="Manage HR Admin" icon="user">        
            <SidebarLink title="Add Admin" route="/forms/add_admin" onClick={this.hideSidebar} />
            <SidebarLink title="List Admin"  route="/forms/admin_list" onClick={this.hideSidebar} />
          </SidebarCategory> */}
          <SidebarCategory title="Manage Employee" icon="users">
            <SidebarLink title="Add Employee"  route="/forms/add" onClick={this.hideSidebar} />
            <SidebarLink title="List Employee"  route="/forms/users" onClick={this.hideSidebar} />
          </SidebarCategory>
          {org}   
          {attendance}
         <SidebarCategory title="Manage Leaves" icon= "file-add"  >
            <SidebarLink title="Add Leave"  route="/forms/add_leave" onClick={this.hideSidebar} />   
            <SidebarLink title="Leaves List"  route="/forms/leave_list" onClick={this.hideSidebar} />  
          </SidebarCategory> 
          <SidebarCategory title="Announcements" icon="bullhorn">
            <SidebarLink title="Add Announcements"  route="/forms/announcements_add" onClick={this.hideSidebar} />   
            <SidebarLink title="Announcements List"  route="/forms/announcements_list" onClick={this.hideSidebar} />  
          </SidebarCategory> 
          {manage_ads}
          {sign_up}
          <SidebarLink title="System Logs" icon="smartphone" route="/forms/logs_list" onClick={this.hideSidebar} />         
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
