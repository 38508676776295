import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;
class Evidence_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude', prop: 'user_lat'},
      { title: 'Longitude', prop: 'user_long'},
      { title: 'Date', prop: 'date'},
      { title: 'Time', prop: 'time'},
      { title: 'Panic Evidence', prop: 'action',cell: (row)=><div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() =>this.view(row.id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + row.user_lat +","+row.user_long}><img id={row.id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  view(e) { 
    this.props.history.push('/forms/evidence_view/'+e);
  }

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/add/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    fetch(myConstClass.BASE_URL+'/user/getUserEvidence')
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.rows.forEach(element => {
      element.action = <div><center><i id={element.id} className="fa fa-eye ptr_css" onClick={() =>this.view(element.id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={element.id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
       i++;
      });
      this.setState({posts: response})
    })
    .catch(err => console.log(err))
        
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Evidence
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}
<MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
    
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Evidence_list);
