import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import { TimePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
// import TimeFnsUtils from '@time-io/time-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderTimePickerField from '../../../shared/components/form/TimePicker';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class SignUp_view extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      posts1:[],
      formData: {

    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  } 
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
    fetch(myConstClass.BASE_URL+'user/getRequestInfo/'+answer_array['3'])
   .then(response => response.json())
   .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
      
    }
  }

  componentWillMount(){
   
    fetch(myConstClass.BASE_URL+'attendance/get_all_employees/')
   .then(response => response.json())
   .then(response =>{this.setState({posts1: response})}).catch(err => console.log(err))      
  }
  
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => { 
  this.setState({ date });
};

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    const answer_array = this.props.location.pathname.split('/');
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`attendance/edit_attendance/`+answer_array['3'], {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Attendance Updated Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/attendance/');
              }else{
                toast('Attendance Updated Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const  { posts1} = this.state;
    const name = formData.name;
    // const file_link = `http://freeattendance.nutraglobe.com/backend/uploads/`+formData.personal_doc;
    const file_link = `http://freeattendance.nutraglobe.com/backend/uploads/`+formData.personal_doc;

    // const file_link = `http://localhost/fap/uploads/banners/`+formData.image;

    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Request Details of  {formData.name}</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              informationdata
        </h3> */}
      </Col>
    </Row>
    <Row>   
   
    <Card>
      <CardBody>
        <div className="card__title">
       </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />     
        </div>        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >          
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Name :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{name}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
       <Row>  
        <Col md={3} lg={3}>      
          <h5>Position :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.position}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Organization :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.organization}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Email :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.email}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Mobile No :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.mobile_no}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Website :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.website}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>City :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.city}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>  
        <Col md={3} lg={3}>      
          <h5>Country :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>{formData.country}</h5> <br/> 
           <Col md={2} lg={2}></Col>
        </Col>
        </Row>
        <Row>
        <Col md={3} lg={3}>      
          <h5>Personal Document :</h5> <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>  
        <a href={file_link}><h5>{formData.personal_doc}</h5></a>
        </Col>
       </Row>
       <br />  
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'signup_view', // a unique identifier for this form
})(withTranslation('common')(SignUp_view));
