/* eslint-disable no-alert */
import * as myConstClass from '../../constant.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function showResults(values) {
  await sleep(500); // simulate server latency
  //window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
  
  fetch(myConstClass.BASE_URL+`/user/add/`, {
  method: 'POST',
  body: JSON.stringify(values, null, 2),
}).then(response =>  response.json()).then((response) => {
  if(response == true){
    toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
    
  }else{
    toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
  }
})
.catch();
});
