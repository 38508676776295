import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import { CSVLink, CSVDownload } from "react-csv";
class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      org: [],
      hrAdmin: [],
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Employee', prop: 'employee_id', filterable: true },
      { title: 'Date', prop: 'attendance_date' },
      { title: 'In-Time', prop: 'clock_in', filterable: true },
      { title: 'Out-Time', prop: 'clock_out' }, 
      { title: 'Timezone', prop: 'timezone' },
      { title: 'Action', prop: 'action',cell: (row)=><div><i id={row.id} className="fa fa-remove ptr_css" onClick={() =>this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css"></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css" onClick={() =>this.view(row.id)}></i></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  remove(e) { 
    this.flag = 1;
    fetch(myConstClass.BASE_URL+`attendance/deleteAttendance`, {
    method: 'POST',
    body: e,
  }).then(toast('Attendance Deleted Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),

  fetch(myConstClass.BASE_URL+'attendance/getAttendance')
  .then(response => response.json())
  .then(response =>{
    let i=1;
    response.rows.forEach(element => {
    element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css"></i><i id={element.uid} className="fa fa-eye ptr_css" onClick={() =>this.view(element.uid)}></i></div>;
     // console.log(element);
     //element.uid=i;
     i++;
    });
    this.setState({posts: response})
  })
  .catch(err => console.log(err))
  )
  .catch();

  
  }

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/attendance_edit/'+e);
   }

   view(e){
    this.props.history.push('/forms/attendance_view/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  handleOrgChang= (organization_id) => { 
    this.setState({ organization_id });
  };

  handleAdminChang= (hr_admin_id) => { 
    this.setState({ hr_admin_id });
    setTimeout(
      function() {
        this.getEmployees();
      }
      .bind(this),
      300
  );
  };

  componentDidMount() {
    fetch(myConstClass.BASE_URL+'attendance/getAttendance/')
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.rows.forEach(element => {
      element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css" onClick={() =>this.edit(element.uid)}></i><i id={element.uid} className="fa fa-eye ptr_css" onClick={() =>this.view(element.uid)}></i></div>;
       // console.log(element);
       //element.uid=i;
       i++;
      });
      this.setState({posts: response})
    })
    .catch(err => console.log(err))

    fetch(myConstClass.BASE_URL+'organization/get_all_organizations/')
    .then(response => response.json())
    .then(response =>{this.setState({org: response})}).catch(err => console.log(err))   
    
    fetch(myConstClass.BASE_URL+'user/get_all_hradmin/')
    .then(response => response.json())
    .then(response =>{this.setState({hrAdmin: response})}).catch(err => console.log(err))   
        
  }
  
render() {
  const csvData = [
    [this.state.posts]
  ];
  const { org,hrAdmin} = this.state;
  let optionItems_org = org.map((org) =>
  <option key={org.id} value={org.id}>{org.title}</option>
  );
  let optionItems_hr = hrAdmin.map((hrAdmin) =>
    <option key={hrAdmin.id} value={hrAdmin.id}>{hrAdmin.first_name+' '+hrAdmin.last_name}</option>
  );
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Attendance
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      <Row>
          <div class="col-md-2"></div>
            <div class="col-md-4">
              <h6>Select Organization</h6>
                  <select name ='hr_admin_id' id="hr_admin"  value={this.state.hr_admin_id}  onChange={this.handleAdminChange} style={{width: '289px',height:'32px', border:'1px'}}>
                    {optionItems_hr}
                </select> 
           </div>
          <div class="col-md-4">
          <h6>Select Organization</h6>
              <select name ='organization_id'   value={this.state.organization_id}  onChange={this.handleOrgChange} style={{width: '289px',height:'32px', border:'1px'}}>
                {optionItems_org}
             </select> 
          </div>
          <div class="col-md-2"></div>
          </Row><br/><br/>
              <Button
                    style={{float:'right'}}
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn btn-primary"
                > <CSVLink data={csvData}></CSVLink> Export 
                </Button>
      
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}

    <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Attendance);
