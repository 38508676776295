import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead  } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import '../../../common_db.css';
import 'font-awesome/css/font-awesome.min.css';

class Organization_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'Title', prop: 'title',filterable: true },
      { title: 'Address', prop: 'addressline1',filterable: true },
      { title: 'City', prop: 'city' },
      { title: 'State', prop: 'state'},
      { title: 'Country', prop: 'country' },
      { title: 'Owner', prop: 'owner' },
      { title: 'Status', prop: 'status' },

      { title: 'Action', prop: 'action',cell: (row)=><div><i id={row.id} className="fa fa-remove ptr_css" onClick={() =>this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css"onClick={() =>this.edit(row.id)} ></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };

    
  }

  remove(e) { 
    this.flag = 1;
    fetch(myConstClass.BASE_URL+`organization/deleteOrganization`, {
    method: 'POST',
    body: e,
  }).then(toast('Organization Deleted Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS}),

  fetch(myConstClass.BASE_URL+'organization/getOrganization')
  .then(response =>response.json()
).then(response =>{
    let i=1;  
  response.rows.forEach(element => {
  element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css"></i><i id={element.uid} className="fa fa-eye ptr_css"></i></div>;
   // console.log(element);
   //element.uid = i;
   i++;
  });
  this.setState({posts: response})
})
.catch(err => console.log(err))
  )
  .catch();

  
  }

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/org_edit/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };
  componentWillMount(){

    var admin_id = sessionStorage.getItem('username')
    fetch(myConstClass.BASE_URL+'user/get_log_admin/')
   .then(response => response.json())
   .then(response =>{this.setState({posts: admin_id})}).catch(err => console.log(err))   
  }

  componentDidMount() {
    var admin_id = sessionStorage.getItem('username'); 
    fetch(myConstClass.BASE_URL+'organization/getOrganization/'+admin_id)
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.rows.forEach(element => {
      element.action = <div><i id={element.uid} className="fa fa-remove ptr_css" onClick={() =>this.remove(element.uid)}></i><i id={element.uid} className="fa fa-edit ptr_css" onClick={() =>this.edit(element.uid)}></i></div>;
       // console.log(element);
       //element.uid=i;
       i++;
      });
      this.setState({posts: response})
    })
    .catch(err => console.log(err))
        
  }

render() {
  
  
  // console.log('this.state.posts',this.state.posts.rows);

  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Organizations
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
        
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  table
/> */}
 <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
{/* <MDBTable pagination={true}>
      <MDBTableHead columns={this.columns} />
      <MDBTableBody rows={this.state.posts} />
    </MDBTable> */}
    
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}



export default withTranslation('common')(Organization_list);
