import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasicForm from '../../../Form/BasicForm/index';
import CheckFormControls from '../../../Form/CheckFormControls/index';
import FileUpload from '../../../Form/FileUpload/index';
import FloatingLabelsForm from '../../../Form/FloatingLabelsForm/index';
import Admin from '../../../Form/Admin/index';
import Users from '../../../Form/Users/index';
import User_edit from '../../../Form/User_edit/index';
import Admin_list from '../../../Form/Admin_list/index';
import Admin_edit from '../../../Form/Admin_edit/index';
import Evidence_list from '../../../Form/Evidence_list/index';
import Cop_list from '../../../Form/Cop_list/index';
import Bulk_import from '../../../Form/Bulk_import/index';
import Evidence_view from '../../../Form/Evidence_view/index';
import Cop_view from '../../../Form/Cop_view/index';
import FormDropzone from '../../../Form/FormDropzone/index';
import FormLayouts from '../../../Form/FormLayouts/index';
import FormPicker from '../../../Form/FormPicker/index';
import FormValidation from '../../../Form/FormValidation/index';
import MaskForm from '../../../Form/MaskForm/index';
import MaterialForm from '../../../Form/MaterialForm/index';
import WizardForm from '../../../Form/WizardForm/index';
import Organization from '../../../Form/Organization/index';
import Organizations from '../../../Form/Organizations/index';
import Org_edit from '../../../Form/Org_edit/index';
import Organization_list from '../../../Form/Organization_list/index';
import Attendance from '../../../Form/Attendance/index';
import Attendance_add from '../../../Form/Attendance_add/index';
import Attendance_edit from '../../../Form/Attendance_edit/index';
import Attendance_view from '../../../Form/Attendance_view/index';
import Add_leave from '../../../Form/Add_leave/index';
import Leave_list from '../../../Form/Leave_list/index';
import Leave_edit from '../../../Form/Leave_edit/index';
import Announcements_add from '../../../Form/Announcements_add/index';
import Announcements_list from '../../../Form/Announcements_list/index';
import Announcements_edit from '../../../Form/Announcements_edit/index';
import Sign_up from '../../../Form/Sign_up/index';
import Sign_up_list from '../../../Form/Sign_up_list/index';
import SignUp_view from '../../../Form/SignUp_view/index';
import Add_ad from '../../../Form/Add_ad/index';
import Ads_list from '../../../Form/Ads_list/index';
import Edit_ad from '../../../Form/Edit_ad/index';
import Logs_list from '../../../Form/Logs_list/index';



export default () => (
  <Switch>
    <Route path="/forms/basic_form" component={BasicForm} />
    <Route path="/forms/check_form_controls" component={CheckFormControls} />
    <Route path="/forms/file_upload" component={FileUpload} />
    <Route path="/forms/floating_labels_form" component={FloatingLabelsForm} />
    <Route path="/forms/add" component={FloatingLabelsForm} />
    <Route path="/forms/addbulk" component={Bulk_import} />
    <Route path="/forms/add/:id" component={FloatingLabelsForm} />
    <Route path="/forms/users" component={Users} />
    <Route path="/forms/user_edit" component={User_edit} />
    <Route path="/forms/add_admin" component={Admin} />
    <Route path="/forms/admin_edit" component={Admin_edit} />
    <Route path="/forms/add_admin/:id" component={FloatingLabelsForm} />
    <Route path="/forms/admin_list" component={Admin_list} />
    <Route path="/forms/add_organization" component={Organization} />
    <Route path="/forms/add_organizations" component={Organizations} />

    <Route path="/forms/org_edit" component={Org_edit} />
    <Route path="/forms/organization_list" component={Organization_list} />
    <Route path="/forms/evidence_list" component={Evidence_list} />
    <Route path="/forms/cop_list" component={Cop_list} />
    {/* <Route path="/forms/loc_list" component={Location_list} /> */}
    <Route path="/forms/evidence_view" component={Evidence_view} />
    <Route path="/forms/cop_view" component={Cop_view} />

    <Route path="/forms/form_dropzone" component={FormDropzone} />
    <Route path="/forms/form_layouts" component={FormLayouts} />
    <Route path="/forms/form_picker" component={FormPicker} />
    <Route path="/forms/form_validation" component={FormValidation} />
    <Route path="/forms/mask_form" component={MaskForm} />
    <Route path="/forms/material_form" component={MaterialForm} />
    <Route path="/forms/wizard_form" component={WizardForm} />
    <Route path="/forms/attendance" component={Attendance} />
    <Route path="/forms/attendance_add" component={Attendance_add} />
    <Route path="/forms/attendance_edit" component={Attendance_edit} />
    <Route path="/forms/attendance_view" component={Attendance_view} />
    <Route path="/forms/leave_list" component={Leave_list} />
    <Route path="/forms/add_leave" component={Add_leave} />
    <Route path="/forms/leave_edit" component={Leave_edit} />
    <Route path="/forms/announcements_add" component={Announcements_add} />
    <Route path="/forms/announcements_list" component={Announcements_list} />
    <Route path="/forms/announcements_edit" component={Announcements_edit} />
    <Route path="/forms/sign_up" component={Sign_up} />  
    <Route path="/forms/sign_up_list" component={Sign_up_list} />
    <Route path="/forms/signup_view" component={SignUp_view} />
    <Route path="/forms/add_ad" component={Add_ad} />
    <Route path="/forms/ads_list" component={Ads_list} />
    <Route path="/forms/edit_ad" component={Edit_ad} />
    <Route path="/forms/logs_list" component={Logs_list} />
  </Switch> 
);
