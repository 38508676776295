import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
// import CKEditor from "react-ckeditor-component";
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Sign_up extends Component {

  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      date: Date.now(),
      // content: 'content',
      posts: [],
      organization:[],
      formData: {
        name: '',
        position: '',
        organization: '',
        email: '',
        mobile: '',
        website: '',
        city: '',
        country:'',
    },
    submitted: false,
    }

    this.handleChangeText = this.handleChangeText.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

    //  updateContent(newContent) {
    //   this.setState({
    //    content: newContent
    //  })
    // }

    // onChangeCk(evt){
    //     console.log("onChange fired with event info: ", evt);
    //     var newContent = evt.editor.getData();
    //     this.setState({
    //       content: newContent
    //     })
    //   }

    //  componentDidMount(){  
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //    // alert(answer_array['3']);
    //   fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    //  .then(response => response.json())
    //  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))    
    //   }
    // }
    
 
    handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    }
    handleDateChange = (date) => {  
    this.setState({ date });
    };

    handleChangeText(event) {
      this.setState({value: event.target.value});
    }
  
    componentWillMount(){
    const newLocal = fetch(myConstClass.BASE_URL + 'organization/get_all_organizations/')
        .then(response => response.json())
        .then(response => { this.setState({ organization: response }); }).catch(err => console.log(err));      
    }

    // componentDidMount(){
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //  fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    // .then(response => response.json())
    // .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
        
    //   }
    // }

  
    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`user/add_request/`, {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Request Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/sign_up_list/');
              }else{
                toast('Request Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
      });
   }


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { organization} = this.state;
    
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Sign Up Form</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>       
    <Card>
      <CardBody>
        <div className="card__title">
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        </div>        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >          
        <Row>        
        <Col md={6} lg={6}> 
        <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="name"
              placeholder="Name"
              label="Name"
              value={formData.name}
             validators={['required']}
             errorMessages={['this field is required']}
            />  
        <div>
        <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="organization"
              placeholder="Organization"
              label="Organization"
              value={formData.organization}
             validators={['required']}
             errorMessages={['this field is required']}
            />  
        </div>         
        <br/>    
        <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="mobile_no"
              placeholder="Mobile No"
              label="Mobile No"
              value={formData.mobile_no}
             validators={['required','matchRegexp:^[0-9]+$']}
             errorMessages={['this field is required','Enter Numbers..']}
            />
            <br/>
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="city"
              placeholder="City"
              label="City"
              value={formData.website}
            />
            </Col>
            <Col md={6} lg={6}> 
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="position"
              placeholder="position"
              label="Position"
              value={formData.position}
             validators={['required']}
             errorMessages={['this field is required']}
            />      
             <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="email"
              placeholder="Email"
              label="Email"
              value={formData.email}
             validators={['required','isEmail']}
             errorMessages={['this field is required','Enter valid email address']}
            />
             <br/>
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="website"
              placeholder="Website"
              label="Website"
              value={formData.website}
            />          
           <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="country"
              placeholder="Country"
              label="City"
              value={formData.country}
            />
         </Col>       
        </Row>
        <Row>
      <Col md={12}>
        <Col md="3"> </Col>    
         <Col md="3">
            <h5><b>Please Note *</b></h5><br/> 
            <h5>1) Organisation must be a registered Identity in the country of issue.</h5><br/>
            <h5>2) Personal ID Document is need to verify system operator.</h5><br/>
            <h5>3) Subject to terms and conditions.</h5><br/>
         </Col>
         </Col>
      </Row>
     <br />
    <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitted}
    >
        {
            (submitted && 'Your form is submitted!')
            || (!submitted && 'Submit')
        }
        </Button>
     </ValidatorForm>
    </CardBody>
  </Card>
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
 </Row>
</Container>
)
}
}



export default reduxForm({
  form: 'sign_up', // a unique identifier for this form
})(withTranslation('common')(Sign_up));
