import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import renderTimePickerField from '../../../shared/components/form/TimePicker';
import renderFileInputField from '../../../shared/components/form/FileInput';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Org_edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      formData: {
        title: '',
        addressline1: '',
        addressline2:'',
        city:'',
        state:'',
        country:'',
        owner:'',
        monday_in_time:'',
        monday_out_time:'',
        tuesday_in_time:'',
        tuesday_out_time:'',
        wednesday_in_time:'',
        wednesday_out_time:'',
        thursday_in_time:'',
        thursday_out_time:'',
        friday_in_time:'',
        friday_out_time:'',
        saturday_in_time:'',
        saturday_out_time:'',
        sunday_in_time:'',
        sunday_out_time:'',

    },
    submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
   fetch(myConstClass.BASE_URL+'organization/getOrganizationInfo/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
      
    }
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleStatusChang= (status) => {
  
  this.setState({ status });
};


handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    const answer_array = this.props.location.pathname.split('/');
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`organization/edit_organization/`+answer_array['3'], {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Organization Updated Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/organization_list/');
              }else{
                toast('Organization Updatioon Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {

    let status = null;

    var admin_id = sessionStorage.getItem('username'); 
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    let optionItems = <option value="">Select Status</option>;
    let optionItems1 = <option value="0">Pending</option>;
    let optionItems3 = <option value="1">Approved / In-Active</option>;

    let optionItems2 = <option value="2">Active</option>;


    if(formData.status == 1){
     status =  (<div>
      <h6>Select Status</h6>
      <select name ='status'  value={this.state.status}  onChange={this.handleStatusChange}   style={{width: '512px',height:'32px', border:'1px'}}>
        {optionItems}
        {optionItems2}
    </select>
     </div>);  
    } else if(admin_id == 10) {
  
      status =  (<div>
        <h6>Select Status</h6>
      <select name ='status' value={this.state.status}  onChange={this.handleStatusChange} style={{width: '512px',height:'32px', border:'1px'}}>
        {optionItems1}
        {optionItems3}
        
    </select>
      </div>);  
    } else {
      status = (<div>
        <h6>Select Status</h6>
      <select name ='status' value={this.state.status}  onChange={this.handleStatusChange} style={{width: '512px',height:'32px', border:'1px'}}>
        {optionItems1}
        
    </select>
      </div>);  
    }
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Organization</h3>
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody> 
        <div className="card__title">
       </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
       <Row>         
        <Col md={6} lg={6}>           
          <TextValidator
            onChange={this.handleChange}
              name="title"
              placeholder="Title"
              label="Title"
              className="material-form__field"
              value={formData.title}
             validators={['required']}
             errorMessages={['this field is required']}
            />        
           <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="addressline1"
              placeholder="Address line 1"
              label="Address line 1"
              value={formData.addressline1}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="addressline2"
              placeholder="Address line 2"
              label="Address line 2"
              value={formData.addressline2}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/>
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="city"
              placeholder="City"
              label="City"
              value={formData.city}
            />
          <br/>    
        </Col>      
        <Col md={6} lg={6}>         
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="state"
              placeholder="State"
              label="State"
              value={formData.state}
            />
           <br/> 
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="country"
              placeholder="Country"
              label="Country"
              value={formData.country}
            />
          <br/> 
          <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="owner"
              placeholder="Owner"
              label="Owner"
              value={formData.owner}
             validators={['required']}
             errorMessages={['this field is required']}
            />
          <br/> 
        </Col>
        <Col md={3} lg={3}>      
          <h5>Day</h5>
          <br/> 
        </Col>
        <Col md={2} lg={2}></Col>
        <Col md={3} lg={3}>       
          <h5>In-Time</h5>
            <br/> 
        </Col>
        <Col md={3} lg={3}>       
          <h5>Out-Time</h5>
          <br/> 
        </Col>
       <Col md={4} lg={4}>      
          <h5>Monday</h5>
          <br/> <br/>
          <h5>Tuesday</h5>
          <br/> <br/>
          <h5>Wednesday</h5>
          <br/>  <br/>
          <h5>Thursday</h5>
          <br/>  <br/>
          <h5>Friday</h5>
          <br/>  <br/>
          <h5>Saturday</h5>
          <br/>  <br/>
          <h5>Sunday</h5>
          <br/> 
        </Col>
        <Col md={3} lg={3}>
        <div>
        <Field
              name="monday_in_time"
              component={renderTimePickerField}
              value={formData.monday_in_time}
            /></div><br />
         <Field
              name="tuesday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="wednesday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="thursday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="friday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="saturday_in_time"
              component={renderTimePickerField}
            /> <br/>
          <Field
              name="sunday_in_time"
              component={renderTimePickerField}
            /> <br/>
        </Col>
        <Col md={3} lg={3}>
        <Field
              name="monday_out_time"
              component={renderTimePickerField}
            /><br />
         <Field
              name="tuesday_out_time"
              component={renderTimePickerField}
            /> <br/>
         <Field
              name="wednesday_out_time"
              component={renderTimePickerField}
            /> <br/>
         <Field
              name="thursday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="friday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="saturday_out_time"
              component={renderTimePickerField}
            /> <br/>
        <Field
              name="sunday_out_time"
              component={renderTimePickerField}
            /> <br/>
        </Col>
        <Col md={6} lg={6}>
        {status}
        </Col>
          {/* <Col md={3} lg={3}>
            <div className="previewComponent"> 
             <TextValidator
              label="Select Banner 1"
            />
             <input className="fileInput" 
              name = 'banner_1'
              id= 'file'
              component={renderFileInputField}
              type="file" 
              onChange={(e)=>this._handleImageChange(e)} />
               <div className="imgPreview">
                 {$imagePreview}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 2"
            />
             <input className="fileInput" 
              label= 'Select Banner 2'
              type="file" 
              onChange={(e)=>this._handleImage2Change(e)} />
               <div className="imgPreview">
                 {$imagePreview2}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 3"
            />
             <input className="fileInput" 
              label= 'Select Banner 3'
              type="file" 
              onChange={(e)=>this._handleImage3Change(e)} />
               <div className="imgPreview">
                 {$imagePreview3}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 4"
            />
             <input className="fileInput" 
              label= 'Select Banner 4'
              type="file" 
              onChange={(e)=>this._handleImage4Change(e)} />
               <div className="imgPreview">
                 {$imagePreview4}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 5"
            />
             <input className="fileInput" 
              label= 'Select Banner 5'
              type="file" 
              onChange={(e)=>this._handleImage5Change(e)} />
               <div className="imgPreview">
                 {$imagePreview5}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 6"
            />
             <input className="fileInput" 
              label= 'Select Banner 6'
              type="file" 
              onChange={(e)=>this._handleImage6Change(e)} />
               <div className="imgPreview">
                 {$imagePreview6}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 7"
            />
             <input className="fileInput" 
              label= 'Select Banner 7'
              type="file" 
              onChange={(e)=>this._handleImage7Change(e)} />
               <div className="imgPreview">
                 {$imagePreview7}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 8"
            />
             <input className="fileInput" 
              label= 'Select Banner 8'
              type="file" 
              onChange={(e)=>this._handleImage8Change(e)} />
               <div className="imgPreview">
                 {$imagePreview8}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}></Col>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 9"
            />
             <input className="fileInput" 
              label= 'Select Banner 9'
              type="file" 
              onChange={(e)=>this._handleImage9Change(e)} />
               <div className="imgPreview">
                 {$imagePreview9}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}>
            <div className="previewComponent">
             <TextValidator
              label="Select Banner 10"
            />
             <input className="fileInput" 
              label= 'Select Banner 10'
              type="file" 
              onChange={(e)=>this._handleImage10Change(e)} />
               <div className="imgPreview">
                 {$imagePreview10}
               </div>
             </div> <br />  <br />
           </Col><br/><br/><br/>
           <Col md={3} lg={3}></Col>            */}
      </Row>
      {/* <br />    <br />     <br />     <br />     <br />    */}
      <br />       
             <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Update')
                    }
                </Button>           
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )
  }
}

export default reduxForm({
  form: 'org_edit', // a unique identifier for this form
})(withTranslation('common')(Org_edit));
