import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
// import CKEditor from "react-ckeditor-component";
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Announcements_edit extends Component {

  constructor(props) {
    super(props);
    // this.updateContent = this.updateContent.bind(this);
    this.state = {
      date: Date.now(),
      // content: 'content',
      posts: [],
      organization:[],
      formData: {
        organization: '',
        title: '',
        announcement:'',
    },
    submitted: false,
    }
    this.handleChangeText = this.handleChangeText.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

    //  updateContent(newContent) {
    //   this.setState({
    //    content: newContent
    //  })
    // }

    // onChangeCk(evt){
    //     console.log("onChange fired with event info: ", evt);
    //     var newContent = evt.editor.getData();
    //     this.setState({
    //       content: newContent
    //     })
    //   }

    //  componentDidMount(){  
    //   const answer_array = this.props.location.pathname.split('/');
    //   if(answer_array['3']){
    //    // alert(answer_array['3']);
    //   fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    //  .then(response => response.json())
    //  .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))    
    //   }
    // }
    
 
    handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    }
    handleDateChange = (date) => {  
    this.setState({ date });
    };

    handleChangeText(event) {
      this.setState({value: event.target.value});
    }
  
    componentWillMount(){
    const newLocal = fetch(myConstClass.BASE_URL + 'organization/get_all_organizations/')
        .then(response => response.json())
        .then(response => { this.setState({ organization: response }); }).catch(err => console.log(err));      
    }

    componentDidMount(){
      const answer_array = this.props.location.pathname.split('/');
      if(answer_array['3']){
     fetch(myConstClass.BASE_URL+'announcements/getAnnouncementsInfo/'+answer_array['3'])
    .then(response => response.json())
    .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
        
      }
    }

  
    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 5000);
            const answer_array = this.props.location.pathname.split('/'); 
      fetch(myConstClass.BASE_URL+`announcements/edit_announcements/`+answer_array['3'], {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Announcements Updated Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/announcements_list/');
              }else{
                toast('Announcements Updation Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
      });
   }


  render() {
    const { formData, submitted } = this.state;
    const { posts} = this.state;
    const { organization} = this.state;
    let optionItems = organization.map((organization) =>
    <option key={organization.id} value={organization.id}>{organization.title}</option>
);
    return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Announcements</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>       
    <Card>
      <CardBody>
        <div className="card__title">
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />        
        </div>        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >          
        <Row>        
        <Col md={6} lg={6}>           
        {/* <div>
         <Field
              name="organization_id"
              component={renderTextField}
              select
              label="Select Organization">
              <MenuItem className="material-form__option" value= {organization.id}>{organization.title}</MenuItem>
            </Field>
        </div>          */}
        <div>
            <h6>Select Organization</h6>
              <select name ='organization_id'  value={formData.organization_id} style={{width: '512px',height:'32px', border:'1px'}}>
                {optionItems}
             </select> 
          </div> 
        <br/>          
        <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="title"
              placeholder="Title"
              label="Title"
              value={formData.title}
             validators={['required']}
             errorMessages={['this field is required']}
            />
           <br/>
           {/* <CKEditor 
              name="announcement"
              placeholder="Make Announcement Here"
              activeClass="p10" 
              content={this.state.content} 
              events={{
                "change": this.onChange
              }}
             /> */}

               {/* <textarea name="announcement"
                label="Announcement"
               value={formData.announcement}
               onChange={this.handleChangeText} cols={50} rows={5}
          /> */}
            <TextValidator
            onChange={this.handleChange}
            className="material-form__field"
              name="temp_data"
              placeholder="Announcement"
              label="Announcement"
              value={formData.temp_data}
             validators={['required']}
             errorMessages={['this field is required']}
            />
         </Col>       
        </Row>
     <br />
    <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitted}
    >
        {
            (submitted && 'Your form is submitted!')
            || (!submitted && 'Submit')
        }
        </Button>
     </ValidatorForm>
    </CardBody>
  </Card>
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
 </Row>
</Container>
)
}
}



export default reduxForm({
  form: 'announcements_edit', // a unique identifier for this form
})(withTranslation('common')(Announcements_edit));
